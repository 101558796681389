.contact-form{
    display: flex;
    height: 80vh;
    padding: 0 3rem 0 3rem;
    margin-top: 18rem;
    /* background-color: rgb(197, 186, 208); */
}
.title{
    font-size: 52px;
    color: blueviolet;
}
.contact-right{
    display: flex;
    justify-content: center;
    position: relative;
    flex: 1;
}

.contact-right>form{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}
.contact-right .user{
    width: 20rem;
    height: 2rem;
    padding: .3rem;
    outline: none;
    border: 2px solid rgb(134, 13, 124);
    border-radius: 7px;
    font-size: 20px;
}

textarea{
    height: 3rem!important;
}

.c-blurl{
    top: 1rem;
    left: 7rem;
}

.btn{
    border-radius: 10px;
    border: none;
    color: rgb(9, 9, 10);
    font-size: 24px;
    background-color:rgb(158, 81, 196);
    padding: 12px 12px;
    box-shadow: 0px 20px 24px 3px rgb(105, 75, 113);
    flex: .3;
    margin: 20px;
    width: 10rem;
    height: 1rem;
}

.btn:hover{
    cursor: pointer;
    color: cornflowerblue;
    background-color: aliceblue;
    border: 3px solid rgb(70, 15, 15);
}

@media  screen and (max-width: 480px) {
    .contact-form{
       margin-top: 15px;
       display: block;
    }
    .title{
        margin-bottom: 10px;
        font-size: 20px;

    }
    .contact-right .user{
        width: 12rem;
        height: 1rem;
    }
    .btn{
        width: 6rem;
        font-size: 16px;
        height: 0.5rem;
    }
}