.home{
    display: flex;
    margin-top: 10rem;
    margin-left: 1rem;
    align-items: center;
    color: rgba(16, 15, 18, 0.883);
    /* height: 77vh; */
}

.home-left{
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    align-items: center;
}

.home-detail{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.home-detail>:nth-child(1){
    color: rgba(48, 39, 48, 0.892);
    font-weight: bold;
    font-size: 3.8rem;
}

.home-detail>:nth-child(2){
    color: rgba(59, 19, 50, 0.794);
    font-weight: bold;
    font-size: 3.5rem;
}

.home-detail>:nth-child(3){
    color: rgb(28, 15, 27);
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 8px;
}

.home-right{
    flex: 1;
}

.home-btn{
    border-radius: 10px;
    border: none;
    color: rgb(41, 64, 64);
    font-size: 24px;
    background-color:rgb(111, 199, 247);
    padding: 12px 12px;
    box-shadow: 0px 20px 24px 3px royalblue;
    flex: .3;
    margin: 20px;
    width: 10rem;
    height: 4rem;
}

.home-btn:hover{
    cursor: pointer;
    color: cornflowerblue;
    background-color: aliceblue;
    border: 3px solid rgb(70, 15, 15);
}
.logo{
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-top: 2rem;
}

.logo1:hover{
    cursor: pointer;
    color: cornflowerblue;
    background-color: aliceblue;
    border: 3px solid rgb(70, 15, 15);
}

.logo2:hover{
    cursor: pointer;
    color: cornflowerblue;
    background-color: aliceblue;
    border: 3px solid rgb(70, 15, 15);
}

.logo3:hover{
    cursor: pointer;
    color: cornflowerblue;
    background-color: aliceblue;
    border: 3px solid rgb(70, 15, 15);
}

/* .home-right{
    flex: 1;
    position: relative;
}

.home-right>img{
    border-radius: 4px solid black;
    transform: scale(1);
} */

@media  screen and (max-width: 780px) {
    .home{
        flex-direction: column;
        height: 64rem;
        gap: 4rem;
        font-size: 1.2rem;
    }
    .home-right{
        transform: scale(0.8);
        left: -3rem;
    }
    /* .home-right .blure{
        display: none;
    } */
    .home-detail{
        font-size: 1.2rem;
    }
}
