.navbar{
    display: flex;
    justify-content: space-between;
}

.navbarleft{
    margin-top: 1rem;
    align-items: center;
    display: flex;
    font-size: 2rem;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif ;
    margin-left: 10px; 
    color: rgb(81, 70, 92);
    /* gap: 2rem; */
    /* flex: 1; */
}

.navbarright{
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: .5;
    font-weight: 500;
}

.navbarrightlist{
    flex: 4;
}
.navbarrightlist ul{
    display: flex;
    gap: 4rem;
    margin-right: 3rem;
}

.navbarrightlist ul li:hover{
    cursor: pointer;
    color: plum;
}

.contacBtn{
    border-radius: 10px;
    border: none;
    color: rgb(41, 64, 64);
    font-size: 24px;
    background-color:rgb(111, 199, 247);
    padding: 12px 20px;
    box-shadow: 0px 20px 24px 3px royalblue;
    flex: 1;
    margin: 10px;
    margin-right: 100px;
}

.contacBtn:hover{
    cursor: pointer;
    color: cornflowerblue;
    background-color: aliceblue;
    border: 3px solid rgb(70, 15, 15);
}

@media  screen and (max-width: 480px) {
    .navbarrightlist{
        display:none;
    }
    .contacBtn{
        display:none;
    }
}