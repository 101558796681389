.skill>h1{
    padding: .5rem 2rem .5rem 2rem;
    display: grid;
    margin-bottom: 5rem;
    margin-top: 10rem;
    /* align-items: center; */
    /* justify-content: center; */
    /* display: grid; */
}
.logo{
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    /* display: grid; */
    /* flex-direction: column; */
    display: flex;
    flex-wrap: wrap;

}

.skill-detail>h1{
    font-weight: bolder;
    /* font-size: larger; */
    font-size: 52px;
    color: rgb(82, 22, 83);
    /* margin-right: 800px; */
    /* align-items: flex-start; */
    /* display: grid; */
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    display: flex;
  /* flex-wrap: wrap; */
}

.addskill{
    font-size: 32px;
    margin-left: 110px;
}

.css{
    /* flex-direction: column; */
    width: 350px;
    height: 80px;
    /* background-color: rgb(211, 201, 188); */
    text-align: center;
    /* margin: 10px; */
    border-radius: 10px;
    background-color:rgb(196, 196, 174);
    /* display: grid; */
    /* margin-right: 650px; */
    /* grid-template-columns: repeat(12,  minmax(0, 1fr)); */
    /* grid-template-rows: auto; */
    margin-bottom: 6rem;
    border: 2px solid rgb(82, 15, 74);
    padding: 1rem 1rem;
    grid-template-columns: 200px 200px 200px;
    display: flex;
    flex-wrap: wrap;
}

.css:hover{
    cursor: pointer;
    color: cornflowerblue;
    background-color: aliceblue;
    border: 3px solid rgb(70, 15, 15);
}

@media  screen and (max-width: 480px) {
    .skill{
        display: contents;
        
        
    }
    .css{
        height: auto;
        width: auto;
    }
}