.footer{
    display: flex;
    align-items: center;
}

.footer-content{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 2rem;
    gap: 2em;
    color: rgb(2, 40, 40);
}

.footer-icons{
    display: flex;
    gap: 1rem;
}

