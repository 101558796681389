.Main{
    background: radial-gradient(circle at center, rgba(205, 239, 248, 0.961) 40%, #f6e0f8fb 100%);
    background: radial-gradient(circle at center, rgb(248, 248, 244) 30%, rgb(238, 244, 243) 100%);
}

@media  screen and (max-width: 480px) {
    .Main{
        padding: 0.5rem 1rem;
        overflow: hidden;
        width: 100%;
		/* height: auto; */
		/* overflow-y: hidden; */
    }
}