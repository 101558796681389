/* .project>img{
        height: 50%;
        width: 40%;
}

.project{
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 180px;
    margin-bottom: 40px;
}

.project-btn{
    border-radius: 10px;
    border: none;
    color: rgb(41, 64, 64);
    font-size: 24px;
    background-color:rgb(111, 199, 247);
    padding: 12px 12px;
    box-shadow: 0px 20px 24px 3px royalblue;
    flex: .3;
    margin: 20px;
    width: 10rem;
    height: 4rem;
}

.project-btn:hover{
    cursor: pointer;
    color: cornflowerblue;
    background-color: aliceblue;
    border: 3px solid rgb(70, 15, 15);
}

@media  screen and (max-width: 480px) {
    .project{
        padding: 0;
        margin-left: 90px;
        margin-bottom: 2rem;
    }
    .project-btn{
        font-size: 16px;
        width: 5rem;
        height: 2rem;
    }
} */
.projects{
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    display: grid;
    flex-direction: column;
/* margin-right: 400rem; */
}

.projectlist{
    display: grid;
    /* grid-template-columns: 200px 200px 200px; */
    /* place-items: center; */
}

.projectcontent{
    width: 500px;
    height: auto;
    /* text-align: center; */
    margin: 10px;
    text-align: center;
    border-radius: 10px;
    /* background-color:rgb(196, 196, 174); */
    display: grid;
    gap: 6rem;
    margin-top: 80px;
    margin-right: 650px;
    grid-template-columns: 1fr 1fr;
    max-height: fit-content;
    /* grid-template-columns: repeat(5, 1fr 2fr); */
    /* grid-template-rows: auto; */
    margin-bottom: 6rem;
}

.projecttitle{
    font-weight: bolder;
    /* font-size: larger; */
    font-size: 52px;
    color: rgb(82, 22, 83);
    /* margin-right: 800px; */
    /* align-items: flex-start; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.addrecente{
    font-size: 32px;
}

.projectcontent .imageproject{
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    
}
.projectBtn{
    border-radius: 1px;
    border: none;
    color: rgb(13, 15, 15);
    font-size: 16px;
    background-color:rgb(173, 150, 175);
    padding: 2px 2px; 
    box-shadow: 0px 20px 24px 3px rgb(87, 103, 151);
    flex: .1;
    margin: 2px;
    width: 6rem;
    height: 2rem;
}
/* .mainproject {
    /* justify-content: center; */
    /* display: flex; */


/* .projecttitle {
    font-size: 70px;
    font-weight: bold;
    display: inline;
    align-items: center;
    justify-content: center;
    position: relative;
    color: rgb(116, 45, 117);
    margin-left: 900px;
    margin-bottom: 40px;
} */
.projectBtn:hover{
    cursor: pointer;
    color: cornflowerblue;
    background-color: aliceblue;
    border: 3px solid rgb(70, 15, 15);
}

.project-title{
    font-size: 25px;
    font-weight: bold;
}

.project-box {
    height: 500px;
    width: 500px;
    background-color: rgb(242, 238, 231);
    display: inline-block;
    border: 2px solid rgb(82, 15, 74);
    padding: 1rem 1rem;
    border-radius: 18px;
}

.project-box:hover{
    cursor: pointer;
    color: cornflowerblue;
    background-color: aliceblue;
    border: 3px solid rgb(70, 15, 15);
}

@media  screen and (max-width: 480px) {
    .project-box{
        /* flex-direction: column; */
        /* height: 64rem; */
        gap: 2rem;
        display: contents;
        margin: 5px;
        height: auto;
        width: auto;
        overflow:hidden;
        
    }
    .projectcontent{
        /* transform: scale(0.3); */
        /* left: -3rem; */
        /* flex-direction:column; */
        display: contents;
        gap: 2rem;
        margin: 5px;
        height: auto;
        width: auto;
        overflow:hidden;
    }
    /* .home-right .blure{
        display: none;
    } */
}