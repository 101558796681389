.about{
    padding: .5rem 2rem .5rem 2rem;
    display: flex;
    margin-bottom: 5rem;
    margin-top: 18rem;
    /* width: 100%; */
    /* height: 60%; */
    /* background: radial-gradient(circle at center, rgba(85, 175, 196, 0.3) 40%, rgba(181, 83, 182, 0.538) 100%); */
    /* background: radial-gradient(circle at center, rgb(228, 223, 245) 30%, rgb(118, 152, 56) 100%); */
}

.about-detail{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%;
    height: auto;
    /* background-color: rgb(183, 154, 175); */
    
}

.about-detail>:nth-child(1){
    font-weight: bolder;
    /* font-size: larger; */
    font-size: 52px;
    color: rgb(82, 22, 83);
    margin-right: 727px;
    /* align-items: flex-start; */
}

.about-detail>:nth-child(4){
    font-size: larger;
    color: rgb(12, 10, 14);
    margin-left: 30px;
    /* align-items: center;
    justify-content: center; */
}

.download-cvbtn{
    border-radius: 10px;
    border: none;
    color: rgb(13, 15, 15);
    font-size: 24px;
    background-color:rgb(162, 54, 172);
    padding: 10px 10px; 
    box-shadow: 0px 20px 24px 3px rgb(87, 103, 151);
    flex: .3;
    margin: 40px;
    width: 18rem;
    height: 5rem;
}

.download-cvbtn:hover{
    cursor: pointer;
    color: rgb(200, 133, 207);
    background-color: rgb(63, 88, 109);
    border: 3px solid rgb(70, 15, 15);
}

.experiance{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-left: 50px;
    font-size: 20px;
    /* align-items: center; */
    /* margin-right: 50px; */
}

.experiance>:nth-child(1){
    font-size: 42px;
    color: rgb(63, 30, 96);
    margin-left: 30px;
    font-weight: bold;
}

.education{
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-left: 90px;
    font-size: 20px;
}

.education>:nth-child(1){
    font-size: 42px;
    color: rgb(71, 36, 104);
    margin-left: 30px;
    font-weight: bold;
}

.project-delete{
    font-size: 40px;
    font-weight: bold;
}
/* .home-right{
    flex: 1;
    position: relative;
    width: 400;
    height: 35;
    border-radius: 4px solid black;
}

.home-right>img{
    border-radius: 4px solid black;
    transform: scale(1);
    width: 400;
    height: 35;
} */

.img{
    border: 10px solid rgb(138, 134, 136);  
    transform: scale(1);
    flex: 1;
    margin-top: 2rem;
    margin-bottom: 10rem;
    margin-left: 1rem;
}
.img:hover{
    cursor: pointer;
    color: rgb(200, 133, 207);
    background-color: rgb(63, 88, 109);
    border: 3px solid rgb(70, 15, 15);
}

@media  screen and (max-width: 480px) {
    .about{
        margin-top: 0;
        flex-direction:column-reverse;
        /* gap: 5rem; */
        /* height: 66rem; */
        padding: 0;

    }
    .about-detail>:nth-child(1){
        margin: 0;
        width: auto;
    }
    .about-detai{
        width: auto;
        margin: 0;
    }
    .about-detail>:nth-child(4){
        margin: 0;
        width: auto;
    }
}